
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton } from '@mui/material';
import cssVars from "../../styles/vars.module.scss"
import { useState, useEffect } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import useTranslation from '../../hooks/useTranslation';

const Counter = ({ counter = 0, setCounter, minVal = 1, maxVal = 999999, onBtnClick, step, leftInStock, date }) => {
    const [qty, setQty] = useState(parseInt(counter));
    const { t } = useTranslation()

    useEffect(() => {
        setCounter(qty)
        if (onBtnClick) {
            const finish = setTimeout(() => {
                if (qty !== counter) {
                    onBtnClick(qty)
                }
            }, 600);
            return () => clearTimeout(finish)
        }
    }, [qty])

    return (
        <Box sx={{ position: "relative" }}>
            <Box sx={{
                border: `2px solid ${cssVars.darkGray}`,
                borderRadius: cssVars.buttonRadius,
                p: "8px 6px",
                display: "flex",
                alignItems: "center"
            }}>
                <IconButton aria-label='decrease quantity' sx={{ p: 0.5, height: "fit-content !important", pointerEvents: (qty <= minVal ? 'none' : 'auto') }} onClick={() => { if (qty > minVal) { setQty(qty - step) } }}>
                    <RemoveIcon
                        sx={qty > minVal ? { color: cssVars.black, cursor: "pointer" } : { color: cssVars.lightGray, cursor: "not-allowed" }}
                    />
                </IconButton>

                <input aria-label='counter input' readOnly={(step !== 1)} type="text" value={qty} onChange={(e) => { e.target.value <= maxVal && e.target.value >= minVal && setQty(parseInt(e.target.value)); }}
                    style={{ padding: "0 4px", border: "none", outline: "none", width: "60px", height: "24px", textAlign: "center", fontSize: "20px", fontWeight: 500 }}
                />
                <IconButton aria-label='increase quantity' sx={{ p: 0.5, height: "fit-content !important", pointerEvents: (qty >= maxVal ? 'none' : 'auto') }} onClick={() => { if (qty < maxVal) { setQty(qty + step) } }}>
                    <AddIcon
                        sx={qty < maxVal ? { color: cssVars.black, cursor: "pointer" } : { color: cssVars.lightGray, cursor: "not-allowed" }}
                    />
                </IconButton>
            </Box>
            <Box sx={{ position: "absolute", bottom: "-22px", left: 0, fontSize: 13 }}>
                {
                    leftInStock &&
                        counter > leftInStock ?
                        <Box sx={{ color: cssVars.yellow }}>
                            <InfoIcon fontSize='small' sx={{ mr: 0.5, mt: -0.5 }} />
                            {(counter - leftInStock) + ' ' + t('in') + ' ~ ' + date + ' ' + t('days')}
                        </Box>
                        :
                        null
                }

            </Box>
        </Box>
    );
}

export default Counter;