import ShowBox from "./show-box";
import style from './productDetails.module.scss'
// import StockIcon from "../../../components/stock-icon/stockIcon";
import React, { } from "react";
import ActionsBar from "./actionsBar";
import TierPrices from "../tier-prices";
import Specs from "./specs";
import SimilarProducts from "../similar-products/similarProducts";
import Link from "next/link";
import { Box, Divider, Grid, Skeleton } from "@mui/material";
import StockStatus from "../../stock-status/stockStatus";
import PriceBox from "../../price-box";
import cssVars from "../../../styles/vars.module.scss";

const ProductDetails = ({ product, similarProducts, url, brands, disable, attributes, hasVariants = false, customerCustomizationLoading, initialData, variantsLoading, ppqLoading, relatedProductsLoading, files }) => {

    return (
        <Grid container columns={12} px={{ xs: .5, lg: 0 }} mx={0} className={style['product-details']}>
            <Grid item xs={12} lg={5}>
                <ShowBox media_gallery={initialData.media_gallery} files={files} />
            </Grid>
            <Grid item xs={12} lg={7} sx={{ pt: 1.5, mt: { xs: 2.5, lg: 0 }, pl: { lg: 8 } }} >
                <Box>
                    <Box className={style.brand}>{
                        brands.map((brand, i) =>
                            <React.Fragment key={brand.url + brand.label}>
                                {
                                    i !== 0
                                        ?
                                        <Divider sx={{ width: "1px", height: "12px", display: "inline-block", mx: 1, bgcolor: "#4B4B4D" }} orientation="vertical" />
                                        :
                                        null

                                }
                                <Link href={"/" + brand.url + ".html"} >{brand.label}</Link>

                            </React.Fragment>
                        )
                    }</Box>
                    <Box component="p" className={style.name}>{initialData.name}</Box>
                    <Box component="p" className={style.model_no}>{initialData.model_no}</Box>
                    <Box sx={{ mb: 1 }} className={style.description} dangerouslySetInnerHTML={{ __html: initialData?.short_description?.html }} />
                    <Specs attributes={attributes} customerCustomizationLoading={customerCustomizationLoading} />
                    {
                        !hasVariants
                            ?
                            <>
                                <TierPrices product={product} />
                                <Box sx={{ display: { lg: "flex" }, justifyContent: "space-between", mt: 2, textAlign: "end" }}>
                                    {
                                        product && !variantsLoading 
                                            ?
                                            <>
                                                <Box mt={{ xs: 4, lg: 0 }} mb={{ xs: 2, lg: 0 }} className={"stock-status " + style.pack}>
                                                    <StockStatus product={product} variant="full" />
                                                </Box>
                                                <PriceBox product={product} variant="pdp" />
                                            </>
                                            :
                                            ppqLoading
                                                ?
                                                <>
                                                    <Box mt={{ xs: 4, lg: 0 }} mb={{ xs: 2, lg: 0 }} className={"stock-status " + style.pack}>
                                                        <Skeleton variant="circular" sx={{ width: "25px", height: "25px", display: "block", mr: "10px" }} /><Skeleton sx={{ width: "100px", display: "block", fontSize: "20px" }} />
                                                    </Box>
                                                    <Box display={{ xs: "flex", lg: "block" }} justifyContent="end">
                                                        <Skeleton variant="rectangular" sx={{ width: "50%", height: "77px", borderRadius: "8px" }} />
                                                    </Box>
                                                </>
                                                :
                                                null
                                    }
                                </Box>
                                {
                                    variantsLoading
                                        ?
                                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ borderRadius: cssVars.radius, mt: 2 }} />
                                        :
                                        <ActionsBar product={initialData} disabled={!Boolean(product)} />

                                }
                            </>
                            :

                            null
                    }
                    {
                        similarProducts?.length > 0
                            ?
                            <SimilarProducts products={[{ ...product, itIsMe: true, my_url: url }, ...similarProducts]} />
                            :
                            relatedProductsLoading
                                ?
                                <Box sx={{ mt: 2.5, display: "flex", justifyContent: "end" }} className={style['similar-products']} >
                                    {
                                        Array.from({ length: 4 }).map((_, i) =>
                                            <Box key={i} sx={{ width: { xs: "50%", lg: "25%" }, px: { lg: 0 } }} >
                                                <Box component="span" sx={{ mt: 0, mx: { lg: 1 }, my: 2, height: "100%", display: "block" }} className={style['similar-product']}>
                                                    <Skeleton variant="rectangular" height={220} sx={{ px: 1, borderRadius: "8px" }} />
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Box>
                                :
                                null
                    }
                </Box>
            </Grid>
        </Grid >
    );
}

export default ProductDetails;