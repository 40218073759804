import { CmsPageParser } from '../../components/cms-parser'
import CustomMetas from "../../components/custom-metas";
import PDP from "../../components/pdp/pdp";
import PLP from "../../components/plp";
import { RANDOM_CAMPAIGN } from "../../graphql/bannerPro/queries";
import { GET_CALIBRATION_BRANDS, GET_CALIBRATION_ITEMS } from '../../graphql/categories/calibration/queries';
import { GET_CATEGORY_FILES, PLP_CATEGORY } from "../../graphql/categories/queries";
import { GET_CMS_BLOCK, GET_CMS_PAGE } from "../../graphql/cms/queries";
import { GET_FILTERS, GET_PLP_PRODUCTS, GET_SORT_FIELDS } from "../../graphql/products/queries";
import { ROUTE } from "../../graphql/route/queries";
import { GET_STORE_CMS_BASE_LINKS, GET_STORE_CONFIG } from "../../graphql/store/queries";
import parseCookieHeader from "../../helpers/parseCookieHeader";
import { parseFilters } from "../../helpers/parseFilters";
import parseSearchParams from "../../helpers/SearchParams";
import { getClient } from "../../Providers/graphqlClient";


const RouteHandler = ({ route, plpProps, cmsProps, ppProps, searchParams }) => {
    let rtn = null

    if (route.type === 'PRODUCT') {
        rtn = <PDP url={ppProps.url} key={route.relative_url} initialData={route} />
    }
    else if (route.type === 'CATEGORY' && route.display_mode !== 'PAGE') {
        const key = route.relative_url + (searchParams.sort ? JSON.stringify(searchParams.sort) : '') + (searchParams.filters ? JSON.stringify(searchParams.filters) : '')
        rtn = <PLP key={key} files={plpProps.categoryFiles} data={plpProps.data} catData={plpProps.catData} filtersData={plpProps.filtersData}
            sortData={plpProps.sortData} cmsBlock={plpProps.cmsBlock} storeConfig={plpProps.storeConfig} campaign={plpProps.randomCampaign}
            isCalibration={plpProps.isCalibration}
        />
    }
    else {
        rtn = <CmsPageParser useContainer key={route.relative_url} id={cmsProps.id} heading={cmsProps.heading} >{cmsProps.content}</CmsPageParser>
    }

    const img = route.type === 'PRODUCT' ? route.prodImg.url : route.image

    return (
        <>
            <CustomMetas title={route.meta_title || route.name || route.title}
                description={route.meta_description}
                keywords={route.meta_keywords || route.meta_keyword}
                page_type="product.item"
                image={img}
                url={process.env.NEXT_PUBLIC_STORE_FRONT + (route.type !== 'PRODUCT' ? `/${route.relative_url}` : `/${route.relative_url.split('/').at(-1)}`)} />
            {rtn}
        </>
    )

}

export default RouteHandler;

export const getServerSideProps = async (context) => {
    const { params } = context
    const searchParams = parseSearchParams(params)

    const cookies = parseCookieHeader(context.req.headers.cookie);
    const token = cookies['auth-store'] ? JSON.parse(decodeURIComponent(cookies['auth-store'])).state.token || null : null
    const client = getClient(context.locale, token)
    const { data: baseLinksData } = await client.query({
        query: GET_STORE_CMS_BASE_LINKS
    })
    const baseLinks = baseLinksData.baseLinks
    const url =
        params.slug?.length > 0
            ?
            (Object.keys(searchParams).length > 0 ? params.slug.slice(0, -1).join('/') : params.slug.join('/'))
            :
            baseLinks.cms_home_page
    console.log(url);

    const isCalibration = url.includes('calibration.html')

    const { data } = await client.query({
        query: ROUTE,
        variables: {
            url
        },
    })

    if (!data.route) {
        return {
            notFound: true,
        }
    }
    if (data.route.relative_url !== url && data.route.relative_url !== "home") {
        return {
            redirect: {
                destination: `/${context.locale}/${data.route.relative_url}`,
            },
        }
    }
    else {
        const rtn = {
            props: {
                route: data.route,
                searchParams
            },
        }
        if (data.route.type === 'CATEGORY') {
            if (data.route.display_mode !== 'PAGE') {
                rtn.props.plpProps = await getPLPdata(client, data.route.id, (searchParams.page || 1), (searchParams.filters || {}), (searchParams.sort || { "position": "ASC" }), data.route.uid, isCalibration)
                if (data.route.display_mode === "PRODUCTS_AND_PAGE") {
                    rtn.props.plpProps.cmsBlock = await getCmsData(client, data.route.landing_page, 'block')
                }
            }
            else {
                rtn.props.cmsProps = await getCmsData(client, data.route.landing_page, 'block')
            }
        }
        else if (data.route.type === 'CMS_PAGE') {
            rtn.props.cmsProps = await getCmsData(client, data.route.identifier, 'page')
        }
        else if (data.route.type === 'PRODUCT') {
            rtn.props.ppProps = {}
            rtn.props.ppProps.url = url
            rtn.props.ppProps.url_key = data.route.url_key
        }
        return rtn
    }
}

const getPLPdata = async (client, id, page, filters, sort, uid, isCalibration) => {
    const pageSize = 11
    console.log(isCalibration);
    console.log(filters);

    console.log({
        pageSize,
        currentPage: page,
        filters: parseFilters(filters, id),
        sort: sort,
    });


    const [{ data }, { data: catData }, { data: filtersData }, { data: sortData }, { data: storeConfigData }, { data: categoryFiles }, { data: campaignData }] = await Promise.all([
        client.query({
            query: isCalibration ? GET_CALIBRATION_ITEMS : GET_PLP_PRODUCTS,
            variables: (
                isCalibration
                    ?
                    {
                        brand: (filters?.brand || null),
                        model: (filters?.model || null),
                        pageSize,
                        currentPage: page,
                    }
                    :
                    {
                        pageSize,
                        currentPage: page,
                        filters: parseFilters(filters, id),
                        sort: sort,
                    }
            )
        }),

        client.query({
            query: PLP_CATEGORY,
            variables: {
                uid: uid
            }
        }),

        client.query({
            query: isCalibration ? GET_CALIBRATION_BRANDS : GET_FILTERS,
            variables: isCalibration ? {} : { id }
        }),

        client.query({
            query: GET_SORT_FIELDS,
            variables: {
                id
            }
        }),

        client.query({
            query: GET_STORE_CONFIG,
        }),

        client.query({
            query: GET_CATEGORY_FILES,
            variables: { uid }
        }),

        client.query({
            query: RANDOM_CAMPAIGN,
            variables: { locations: "plp" }
        }),
    ])

    return {
        data,
        catData,
        filtersData,
        sortData,
        storeConfig: storeConfigData.storeConfig,
        categoryFiles: categoryFiles?.categories?.items?.at(0)?.files || [],
        randomCampaign: campaignData?.randomCampaigns?.at(0),
        isCalibration
    }
}

const getCmsData = async (client, id, type) => {

    if (type === 'page') {
        const { data: pageData } = await client.query({
            query: GET_CMS_PAGE,
            variables: { id }
        })
        return {
            content: pageData.cmsPage.content,
            id,
            heading: pageData.cmsPage.content_heading
        }
    }
    else {
        const { data: blockData } = await client.query({
            query: GET_CMS_BLOCK,
            variables: { id }

        })
        return {
            content: blockData.cmsBlocks.items[0].content,
            id
        }
    }
}
