import { gql } from "@apollo/client";
import { CUSTOM_ATTRIBUTE_FRAGMENT, PRODUCT_FRAGMENT, RELATION_PRODUCT_FRAGMENT } from "./fragments";

export const PPQ = gql`
  ${PRODUCT_FRAGMENT}
  query getProduct($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        ...ProductFragment
        categories {
          name
          url_path
          breadcrumbs {
            category_name
            category_url_path
            category_level
          }
        }
        __typename
      }
    }
  }
`;

export const GET_PRODUCT_ACCESSORIES = gql`
  query getAccessories($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        spare_parts_skus
        suitable_skus
        used_with_skus
        accessories {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_SPARE_PARTS = gql`
  query getSpareParts($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        spare_parts_skus
        spare_parts: accessories {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_SUITABLE_PARTS = gql`
  query getSuitableParts($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        suitable_skus
        suitable_parts: accessories {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_USED_WITH = gql`
  query getUsedWithProducts($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        used_with_skus
        used_with: accessories {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_VARIANTS = gql`
  query getProductVariants($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        variant_attributes
        variant_custom_attributes {
          sku
          code
          value
          option_id
          label
          position
          highlighted
        }
        variant_products {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_KIT = gql`
  query getProductKit($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        product_kit
        product_kit_collection {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_PARENT_KIT = gql`
  query getProductParentKit($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        parent_kit
        parent_kit_collection {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const getMaterialQ = gql`
  query getMaterial {
    customAttributeMetadata(
      attributes: [
        {
          attribute_code: "material_filter"
          entity_type: "catalog_product"
        }
      ]
    ) {
      items {
        attribute_options {
          value
          label
        }
      }
    }
  }
`;

export const GET_ATTRIBUTES = gql`
  query attributesMetadata {
    attributesMetadata(entityType: PRODUCT) {
      items {
        code
        label
        storefront_properties {
          visible_on_catalog_pages
        }
      }
    }
  }
`;

export const RANDOM_COMPAIN = gql`
  query randomCampaigns(
    $locations: [String!]
    $category_ids: [String]
    $tags: [String]
    $pageSize: Int
  ) {
    randomCampaigns(
      locations: $locations
      category_ids: $category_ids
      tags: $tags
      pageSize: $pageSize
    ) {
      title
      description
      icon
      color
      id
      image
      link
      location
      __typename
    }
  }
`;

export const GET_CUSTOMER_CUSTOMIZATION = gql`
  ${CUSTOM_ATTRIBUTE_FRAGMENT}
  query getCustomerCustomization($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        customer_customization {
          tags
          attributes {
            primary {
              ...CustomAttributeFragment
            }
            secondary {
              ...CustomAttributeFragment
            }
            optional {
              ...CustomAttributeFragment
            }
          }
        }
      }
    }
  }
`;

export const GET_RELATED_PRODUCTS = gql`
  query getRelatedProducts($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        related_products {
          name
          sku
          canonical_url
          categories {
            url_path
            level
          }
          small_image {
            url
            label
          }
          customer_customization {
            tags
          }
        }
      }
    }
  }
`;

export const GET_UP_SELL_PRODUCTS = gql`
  query getUpSellProducts($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        upsell_products {
          name
          sku
          url_key
          canonical_url
          small_image {
            url
            __typename
          }
          display_tax {
            regular_price_without_tax
            regular_price_with_tax
            final_price_without_tax
            final_price_with_tax
          }
          custom_stock_item {
            min_sale_qty
            max_sale_qty
            qty_increments
          }
          price_range {
            maximum_price {
              discount {
                amount_off
              }
            }
          }
          availability {
            status
            label
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_FILES = gql`
  query getProductsFiles($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        files {
          size
          title
          url
        }
      }
    }
  }
`;