import { useContext, useState } from "react";
import Counter from "../../../components/counter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useRouter } from "next/router";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import useTranslation from '../../../hooks/useTranslation'
import style from "./productDetails.module.scss"
import { WishListContext } from "../../../Providers/WishListContext";
import { useAuthStore } from "../../../stores/authStore";
import { useGlobalStore } from "../../../stores/globalStore";
import { useSnackbar } from "notistack";
import { Box, Button, useMediaQuery } from "@mui/material";
import { MultiWishListContext } from "../../../Providers/MultiWishListContext";
import WishListSection from "../../product-card/wishListSection";
import cssVars from '../../../styles/vars.module.scss'
import { useCartActions } from "../../../hooks/cart/useCartActions";
const ActionsBar = ({ product, disabled }) => {

    const isAuthenticated = useAuthStore(state => state.token?.length > 0)
    const [showWishLists, setShowWishLists] = useState(false);
    const router = useRouter()
    const [count, setCount] = useState(1)
    console.log(count);

    const { t } = useTranslation();

    const { wishLists: data, isFav, addProductToWishlist, removeProductFromWishlist } = useContext(MultiWishListContext)
    // const { cart, addProductsToCart, addBundleProductsToCart } = useContext(CartContext)

    const { enqueueSnackbar } = useSnackbar()


    const handleFavClick = () => {
        if (isAuthenticated) {
            setShowWishLists(prev => (!prev));
        } else {
            router.push(`/customer/login.html?redirect=${router.asPath}`)
        }
    };

    const isAriba = useGlobalStore(s => s.isAriba)
    const { addProductsToCart, disabled: cartDisabled, addBundleProductsToCart } = useCartActions()
    const cartId = useAuthStore(state => state.cartId);
    const cartReady = !cartDisabled
    const openMiniCart = useGlobalStore(s => s.openMiniCart)
    const setLoading = useGlobalStore(s => s.setFullPageLoading)

    const addToCart = (e, product) => {
        e.preventDefault();
        setLoading(true)
        if (cartReady) {
            if (product.__typename === "BundleProduct") {
                addBundleProductsToCart({
                    variables: {
                        cartId: cartId,
                        sku: product.sku,
                        quantity: count,
                        optionId: product.items[0].option_id,
                        id: [product.items[0].options[0].id]
                    },
                    onCompleted: (data) => {
                        setLoading(false);
                        if (data) {
                            openMiniCart();
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false);
                    },
                });
            } else {
                addProductsToCart({
                    variables: { cartId: cartId, cartItems: [{ sku: product.sku, quantity: count }] },
                    onCompleted: (data) => {
                        setLoading(false)
                        if (data) {
                            if (data.action?.user_errors?.length > 0) {
                                for (let i = 0; i < data.action?.user_errors?.length; i++) {
                                    const userError = data.action?.user_errors.at(i)?.message
                                    enqueueSnackbar(userError, { variant: 'error' })
                                }
                            }
                            else {
                                openMiniCart()
                            }
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false)
                    }
                })
            }
        }
        else {
            setLoading(false)
        }
    }


    const min_qty = product?.custom_stock_item?.min_sale_qty || 1
    const max_qty = product?.custom_stock_item?.max_sale_qty || product?.custom_stock_item?.qty || 9999999
    const step = product?.custom_stock_item?.qty_increments || 1

    return (
        <Box display="flex" mt={2} className={style['actions-bar']} flexWrap="wrap">
            <Button
                aria-label="add to wishlist"
                sx={{
                    display: isAriba ? "none" : "inline-flex", alignItems: "center", justifyContent: "center",
                    mr: 1.5, border: "1px solid",
                    ...(
                        isFav(product.sku)
                            ?
                            { borderColor: cssVars.blue, color: cssVars.blue, "&:hover": { color: cssVars.darkBlue } }
                            :
                            { borderColor: cssVars.darkGray, color: cssVars.darkGray, "&:hover": { color: cssVars.extraDarkGray } }
                    ),
                    minWidth: "unset"
                }}
                className={style['fav-btn']}
            >
                <FavoriteIcon
                    onClick={disabled ? () => { } : handleFavClick}
                />
            </Button>
            <WishListSection
                sku={product.sku}
                wishLists={data?.wishlists}
                show={showWishLists}
                setShowWishLists={setShowWishLists}
                className="pp"
                sx={{ top: 62, left: 0, right: "initial" }}
            />
            <Box display="inline-block" mr={{ lg: 1.5 }} className={style['counter-container']} sx={{ width: { xs: (isAriba ? "100%" : "calc(100% - 62px)"), lg: "150px" }, "& input": { width: { xs: "100% !important", lg: "initial" } } }}>
                <Counter setCounter={(q) => setCount(q)} minVal={min_qty} maxVal={max_qty} step={step} counter={count} className={style.counter} />
            </Box>
            <Button
                sx={{
                    mt: { xs: 1.5, lg: 0 },
                    borderColor: cssVars.blue,
                    bgcolor: cssVars.blue, color: "#fff", "&:hover": { color: "#fff", bgcolor: cssVars.darkBlue },
                    width: {
                        xs: "100%",
                        lg: isAriba ? "calc(100% - 166px)" : "calc(100% - 232px)"
                    }
                }}
                disabled={product?.availability?.status === "not-available"}
                className={style['add-btn']}
                onClick={disabled ? () => { } : (e) => { addToCart(e, product) }}
            >
                <ShoppingBasketIcon />
                {t('Add to cart')}
            </Button>
        </Box>
    );
}

export default ActionsBar;