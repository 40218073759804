/* eslint-disable @next/next/no-img-element */
import useTranslation from '../../../hooks/useTranslation'
import { useContext, useState } from 'react';
import styles from './relation.module.scss'
import Counter from '../../counter';
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WishListSection from '../../product-card/wishListSection';
import { useAuthStore } from '../../../stores/authStore';
import { useGlobalStore } from '../../../stores/globalStore';
import Link from 'next/link';
import { useSnackbar } from 'notistack';
import PriceBox from '../../price-box';
import { Box, Button } from '@mui/material';
import StockStatus from '../../stock-status/stockStatus';
import cssVars from '../../../styles/vars.module.scss';
import { MultiWishListContext } from '../../../Providers/MultiWishListContext';
import { useCartActions } from '../../../hooks/cart/useCartActions';
import { useRouter } from 'next/router';

const Item = ({ product }) => {
    const { t } = useTranslation();
    const { wishLists: data, isFav, addProductToWishlist, removeProductFromWishlist } = useContext(MultiWishListContext)
    const [showWishLists, setShowWishLists] = useState(false);
    const [count, setCount] = useState(product.custom_stock_item.min_sale_qty)
    const isAuthenticated = useAuthStore(state => state.token?.length > 0)

    const { enqueueSnackbar } = useSnackbar();

    const router = useRouter();

    const handleFavClick = () => {
        if (isAuthenticated) {
            setShowWishLists(prev => (!prev));
        } else {
            router.push(`/customer/login.html?redirect=${router.asPath}`)
        }
    };

    const { addProductsToCart, disabled: cartDisabled, addBundleProductsToCart } = useCartActions()
    const cartId = useAuthStore(state => state.cartId);
    const cartReady = !cartDisabled
    const openMiniCart = useGlobalStore(s => s.openMiniCart)
    const setLoading = useGlobalStore(s => s.setFullPageLoading)

    const addToCart = (e, product) => {
        e.preventDefault();
        setLoading(true)
        if (cartReady) {
            if (product.__typename === "BundleProduct") {
                addBundleProductsToCart({
                    variables: {
                        cartId: cartId,
                        sku: product.sku,
                        quantity: count,
                        optionId: product.items[0].option_id,
                        id: [product.items[0].options[0].id]
                    },
                    onCompleted: (data) => {
                        setLoading(false);
                        if (data) {
                            openMiniCart();
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false);
                    },
                });
            } else {
                addProductsToCart({
                    variables: { cartId: cartId, cartItems: [{ sku: product.sku, quantity: count }] },
                    onCompleted: (data) => {
                        setLoading(false)
                        if (data) {
                            if (data.action?.user_errors?.length > 0) {
                                for (let i = 0; i < data.action?.user_errors?.length; i++) {
                                    const userError = data.action?.user_errors.at(i)?.message
                                    enqueueSnackbar(userError, { variant: 'error' })
                                }
                            }
                            else {
                                openMiniCart()
                            }
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false)
                    }
                })
            }
        }
        else {
            setLoading(false)
        }
    }

    const isAriba = useGlobalStore(s => s.isAriba)

    const min_qty = product?.custom_stock_item?.min_sale_qty || 1
    const max_qty = product?.custom_stock_item?.max_sale_qty || product?.custom_stock_item?.qty || 9999999
    const step = product?.custom_stock_item?.qty_increments || 1

    return (
        <Box mx={0} width="100%" className={styles.listItem} position="relative">
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" className='d-flex'>
                    <Link style={{ display: "block" }} href={`/${product.canonical_url}`}>
                        <img src={product.small_image.url} alt="relation item image" className={styles.image} />
                    </Link>
                    <Box className={styles.details}>
                        <Box component="p" className={styles.sku}>{product.sku}</Box>
                        <Link href={`/${product.canonical_url}`} className={styles.name}>{product.name}</Link>
                        <Box mt={1} sx={{ "& div:last-child": { fontSize: "12px" } }}>
                            <StockStatus product={product} variant='full' />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <PriceBox product={product} variant="relation" />
                    <Box display="flex" alignItems="center" justifyContent="end" className={styles.actions}>
                        {
                            product.availability.status !== "not-available"
                                ?
                                <>
                                    <Box sx={{ "&>div>div": { height: "38px" } }}>
                                        <Counter setCounter={(q) => setCount(q)} minVal={min_qty} maxVal={max_qty} counter={count} onBtnClick={(q) => { setCount(q) }} step={step} />
                                    </Box>
                                    <Button
                                        className={`${styles['add-btn']}`}
                                        sx={{
                                            mt: { xs: 3, lg: 0 },
                                            backgroundColor: cssVars.blue,
                                            '&:hover': {
                                                bgcolor: cssVars.darkBlue
                                            },
                                            color: cssVars.white,
                                            bgcolor: cssVars.blue,

                                        }}
                                        onClick={(e) => { addToCart(e, product) }}
                                    >
                                        <ShoppingBasketIcon />
                                        {t('Add to cart')}
                                    </Button>
                                </>
                                :
                                <>
                                    <Box></Box>
                                    <Box></Box>
                                </>
                        }
                        <Box sx={{ display: isAriba ? "none" : "block" }}>
                            <Button
                                aria-label="add to wishlist"
                                className={styles['fav-btn']}
                                sx={{
                                    visibility: isAriba ? 'hidden' : 'visible',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    color: isFav(product.sku) ? cssVars.blue : cssVars.darkGray,
                                    border: `1px solid`,
                                    borderColor: isFav(product.sku) ? cssVars.blue : cssVars.darkGray,
                                    '&:hover': {
                                        color: isFav(product.sku) ? cssVars.darkBlue : cssVars.extraDarkGray,
                                        borderColor: isFav(product.sku) ? cssVars.darkBlue : cssVars.darkBlue
                                    },
                                    minWidth: "unset"

                                }}
                                onClick={() => handleFavClick()}
                            >
                                <FavoriteIcon />
                            </Button>
                            <WishListSection
                                sku={product.sku}
                                show={showWishLists}
                                setShowWishLists={setShowWishLists}
                                sx={{ top: 104, right: 20, left: "initial" }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}

export default Item;